import React , { useContext }from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@iconify/react';
import loginIcon from '@iconify/icons-simple-line-icons/login';
import logoutIcon from '@iconify/icons-simple-line-icons/logout';

import { authContext } from '../../context/authContext';

import '../../scss/nav.scss';


export default function Nav() {
  const auth = useContext(authContext);

  return (
    <nav>
      {auth.isAdmin && auth.activePage === 'explorer' ?
        <Link to='/admin'>
          <div className="btn-primary btn-nav">
            Administration
          </div>
        </Link>
      :
        null
      }
      {auth.activeUser ?
        <>
        <div className="btn-login" onClick={() => auth.setLoginModaleIsActive(true)}>
          <Icon icon={logoutIcon} color="#0092c5" width="20" />
          <span>Déconnexion</span>
        </div>
        </>
        :
        <div className="btn-login" onClick={() => auth.setLoginModaleIsActive(true)}>
          <Icon icon={loginIcon} color="#0092c5" width="20" />
          <span>Connexion</span>
        </div>
      }
    </nav>
  )
}
