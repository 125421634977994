import React , { useContext , useEffect , useState } from 'react';

import { authContext } from '../../context/authContext';
import { validateEmail  } from '../../utils/utils';

import '../../scss/modale.scss';
import close from '../../assets/images/close.svg'

export default function LoginModale(props) {
  const auth = useContext(authContext);

  const [ email , setEmail ] = useState();
  const [ password , setPassword ] = useState();
  const [ message , setMessage] = useState("Completer votre email");
  const [ validateIsDisabled , setValidateIsDisabled ] = useState(true);
  const [ messageStyle , setMessageStyle ] = useState("message");
  const [ resetPasswordActive , setResetPasswordActive ] = useState(false);

  function sumbitLogin() {
    setMessage("");
    auth.loginAction({
      email: email,
      password: password
    })
  }
  
  function sumbitNewPassword() {
    setResetPasswordActive(true)
    setMessage("");
    auth.resetPasswordAction({
      email : email
    });
  }

  function closeModale() {
    auth.setLoginModaleIsActive(false);
    setResetPasswordActive(false)
  }

  function handleChangeEmail(val) {
    if (validateEmail(val) === false ) {
      setMessage("Votre email est invalide");
      setMessageStyle("error");
      setEmail();
    } else {
      setEmail(val);
      if (!password) {
        setMessage("Completer votre mot de passe");
      } else {
        setMessage("");
      }
    } 
  }

  useEffect(() => {
    if(!email && !password) {
      setMessage("Completer votre email");
      setMessageStyle("message")
    }
    if (email && password) {
      setMessage("");
      setMessageStyle("message")
      setValidateIsDisabled(false)
    }
    if (email && !password) {
      setMessage("Completer votre mot de passe");
      setMessageStyle("message")
      setValidateIsDisabled(true)
    } 
    if (auth.loginStatus === 403) {
      setMessage("Utilisateur non trouvé ou inactif");
      setMessageStyle("error")
      setValidateIsDisabled(false)
    }

  }, [email, password , auth.loginStatus])


  function renderLogin() {
    return (
      <>
        {!resetPasswordActive ?
          <>
            <span className={messageStyle}>{message}</span>
            <form>
              <input className="input" autoComplete="current-email" type="text" placeholder="email" onChange={(e) => handleChangeEmail(e.target.value)}/>
              <input className="input" autoComplete="current-password" id="password" type="password" placeholder="mot de passe" onChange={(e) => setPassword(e.target.value)}/>
            </form>
            <button
              className={`btn-primary ${validateIsDisabled? "disabled": "" }`}
              disabled={validateIsDisabled}
              onClick={() => sumbitLogin()}>
              Valider
            </button>
            { email && email !== "" ? 
              <button
                onClick={() => sumbitNewPassword(true)}
              >
                réinitialiser votre mot de passe
              </button>
              :
              null
            }
          </>
          :
          <>
            <h3 style={{ textAlign:'center'}}>Un lien de réinitialisation<br/>du mot de passe à été envoyé à l'adresse<br/> <span className="text-primary">{email}</span></h3>
          </>
        }
      </>
    )
  }

  function renderLogout() {
    return (
      <>
        <span className="user-name">{auth.activeUser.firstname} <span className="last-name">{auth.activeUser.lastname}</span></span>
        <span className="message">est connecté</span>
        <button
          style={{ width: 165 }}
          className="btn-primary icon"
          onClick={() => auth.logout()}>
          Se déconnecter
        </button>
      </>
    )
  }

  return (
    <div className={`${auth.loginModaleIsActive ? "modale-container enter" : "d-none"}`}>
      <div className="modale">
        <img src={close} className="btn-close" alt="close" onClick={() => closeModale()}/>
        <div className="modale-header">
          <h2>Connexion</h2>
          <div className="title-line"></div>
        </div>
        <div className="modale-content login">
          {auth.activeUser ? 
            renderLogout()
            :
            renderLogin()
          }
        </div>
      </div>
    </div>
  )
}
