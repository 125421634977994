import React , { useContext , useEffect , useState } from 'react';

import { authContext } from '../../context/authContext';
import { adminContext } from '../../context/adminContext';
import { validateEmail  } from '../../utils/utils';

import '../../scss/modale.scss';
import close from '../../assets/images/close.svg'

export default function UserModale(props) {
  const auth = useContext(authContext);
  const admin = useContext(adminContext);
  
  const [ lastname , setLastname ] = useState();
  const [ firstname , setFirstname ] = useState();
  const [ email , setEmail ] = useState();
  const [ message , setMessage] = useState("");
  const [ validateIsDisabled , setValidateIsDisabled ] = useState(true);
  const [ messageStyle , setMessageStyle ] = useState("message");

  function sumbitUser() {
    setMessage("");
    var newUser = {
      email: email,
      firstname : firstname ,
      lastname: lastname,
    };
    admin.addUser(newUser);
    setLastname();
    setFirstname();
    setEmail();
    auth.setUserModaleIsActive(false);
  }

  function handleChangeEmail(val) {
    if (validateEmail(val) === false ) {
      setMessage("Votre email est invalide");
      setMessageStyle("error");
    } else {
      setEmail(val);
      if (!firstname) {
        setMessage("Completer le prénom");
      } else {
        setMessage("");
      }
    } 
  }

  useEffect(() => {
    if(!auth.userModaleIsActive) {
      setLastname();
      setEmail();
      setFirstname();
    }
  }, [auth.userModaleIsActive])

  useEffect(() => {
    if ( !lastname && !firstname && !email ) {
      setMessage("Completer le nom");
      setMessageStyle("message")
      setValidateIsDisabled(true)
    } else if (lastname && !firstname && !email) {
      setMessage("Completer le prénom");
      setMessageStyle("message")
      setValidateIsDisabled(true)
    } else if (lastname && firstname && !email) {
      setMessage("Completer l'email");
      setMessageStyle("message")
      setValidateIsDisabled(true)
    } else if (email && firstname && lastname) {
      setMessage("");
      setMessageStyle("message")
      setValidateIsDisabled(false)
    } else {
      setValidateIsDisabled(false)
    }

  }, [email, firstname , lastname])

  function renderAddUser() {
    return (
      <>
        <span className={messageStyle}>{message}</span>
        <input className="input" type="text" placeholder="Nom" onChange={(e) => setLastname(e.target.value)}/>
        <input className="input" type="text" placeholder="Prénom" onChange={(e) => setFirstname(e.target.value)}/>
        <input className="input" type="text" placeholder="Email" onChange={(e) => handleChangeEmail(e.target.value)}/>
        <button
          style={{marginTop: 25 }}
          className={`btn-primary ${validateIsDisabled? "disabled": "" }`}
          disabled={validateIsDisabled}
          onClick={() => sumbitUser()}>
          Valider
        </button>
      </>
    )
  }

  return (
    <div className={`${auth.userModaleIsActive ? "modale-container enter" : "d-none"}`}>
      <div className="modale">
        <img src={close} className="btn-close" alt="close" onClick={() => auth.setUserModaleIsActive(false)}/>
        <div className="modale-header">
          <h2>Ajouter un utilisateur</h2>
          <div className="title-line"></div>
        </div>
        <div className="modale-content">
          {renderAddUser()}
        </div>
      </div>
    </div>
  )
}
