import React , { useContext , useEffect , useState } from 'react';
import { Link } from 'react-router-dom';

import history from "../../utils/history";
import { authContext } from '../../context/authContext';
import { adminContext } from '../../context/adminContext';
import User from '../Partials/User';
import Nav from '../Partials/Nav';
import DataSource from '../Partials/DataSource';

import '../../scss/admin.scss';
import iconSearch from "../../assets/images/search-solid.svg";
import arrow from '../../assets/images/arrow-white.svg'

export default function Admin() {
  const auth = useContext(authContext);
  const admin = useContext(adminContext);
  const [ usersList , setUserList ] = useState(admin.usersList)

  useEffect(() => {
    auth.setActivePage("admin");
    return function cleanup() {
      auth.setActivePage();
    };
  },[auth]);

  useEffect(() => {
    if(!auth.isAdmin) {
      history.push('/')
    }
  },[auth.isAdmin]);

  useEffect(() => {
    setUserList(admin.usersList);
  },[admin.usersList]);

  function handleChangeSearch(value) {
    var filtredList = [];
    if (value) {  
      admin.usersList.map(user => Object.keys(user).forEach(key => {
        if (typeof user[key]  === 'string' && user[key].toLowerCase().includes(value.toLowerCase()) ) {
          filtredList.push(user)
        }
      }))
      var newList = [...new Set(filtredList)]
      setUserList(newList)
    } else {
      setUserList(admin.usersList)
    }
  }

  function renderList() {
    if (usersList.length > 0) {
      return (
        usersList.map((user , i ) => {
          return <User
                    key={i}
                    user={user}
                    authUser={auth.activeUser}
                    options={admin.rolesOptions}
                    updateUser={admin.updateUser}
                    removeUser={admin.deleteUser}
                    />
        })
      )
    } else {
      return (
        <div className="no-results">
          <h3 className="error">aucun résultat ne correspond à votre recherche</h3>
        </div>
      )
    }
  }

  return (
    <div className="admin-container">
      <div className="btn-back">
        <Link to='/' className="btn-primary icon btn-nav">
            <img src={arrow} alt='arrow'/>
            Retour à la visualisation
        </Link>
      </div>
      <Nav />
      <section>
        <div className="section-header data">
          <div>
            <h2>Source de données</h2>
            <div className="title-line"></div>
          </div>
        </div>
        <div className="section-content data">
          {admin.fileData ?
            <DataSource fileData={admin.fileData} />
            :
            null
          }
        </div>
      </section>
      <section>
        <div className="section-header user">
          <div>
            <h2>Utilisateurs</h2>
            <div className="title-line"></div>
          </div>
          <div className="btn-container">
            <button 
              onClick={() => auth.setUserModaleIsActive(true)}
              className="btn-primary" 
              style={{ margin: 0 }}>
              Ajouter un utilisateur
            </button>
            <input className="input search-user" type="text" placeholder="Rechercher" onChange={(e) => handleChangeSearch(e.target.value)}/>
            <div className="icon-search">
              <img className="icon-search" src={iconSearch} alt="search"/>
            </div>
          </div>
        </div>
        <div className="section-content user">
          {renderList()}
        </div>
      </section>
    </div>
  )
}
