import React , { useContext } from 'react';
import dataVisLogo from './../../assets/images/vizioecologo.png';
import { explorerContext } from '../../context/explorerContext';

export default function SideBar(props) {
  const context = useContext(explorerContext);

  const renderIntro = () => {
    return (
      <div className="accueil">
        <img src={dataVisLogo} alt="logo" width="100%"/>
        <div className="underline"></div>
        <p>
          VizioECO est une plate-forme de datavisualisation de l'économie de la métropole de Rennes et du département d'Ille et Vilaine. A travers une nouvelle approche par écosystème et par marché, cette plate-forme vise à simplifier la compréhension du tissu économique local et de ses acteurs économiques. Les emplois salariés privés et publics sont affichés dans cette version de VizioECO.        </p>
        <p>
          Le cumul des emplois par écosystème ne correspond pas à l’emploi salarié total du département, étant donné que certaines entreprises et leurs salariés appartiennent à deux écosystèmes différents. Le total des emplois salariés (privés et publics) est indiqué en haut à gauche de cette page, ainsi que le nombre d’entreprises employeuses pour l’année sélectionnée.        </p>
        <p>Données au 31/12/2021</p>
        <a href="files/tutoriel.pdf" download>Télécharger le tutoriel en pdf</a>
      </div>
    );
  }

  const renderListMarkets = () => {
    if (context.selectedEco && context.selectedEco.years && context.selectedEco.years.length > 0 ) {
      let markets = [];
      context.markets.map((item) => {
        let found = item.years.filter(item => item.year === context.selectedYear);
        if (found.length > 0 && found[0].size > 0) {
          return markets.push(item);
        }
        return item;
      })
      
      return (
        <>
          <h2>Liste des Marchés</h2>
          <h3>{context.selectedEco.label}<br/>
            <span>
              {context.selectedEco.years.filter(item => item.year === context.selectedYear).length > 0 ?
                context.selectedEco.years.filter(item => item.year === context.selectedYear)[0].size
                :
                0
              } emplois<br/>
              <span className="date"> au 31/12/{context.selectedYear}</span>
            </span>
          </h3>
          <div className="list">
            {markets.sort((a, b) => {
                a.size = a.years.filter(item => item.year === context.selectedYear)[0].size
                b.size = b.years.filter(item => item.year === context.selectedYear)[0].size
                return a.size - b.size;
              }).reverse().map((item, index) => {
                if (item.size !== 0) {
                  return (
                    <div key={index} className="list-item">
                      <h4 onClick={() => props.displayVizCompanies(item)}>{item.label}</h4>
                      <span>{item.years.filter(item => item.year === context.selectedYear)[0].size} emplois</span>
                    </div>
                  )
                }
                return null;
            })}
          </div>
        </>
      );
    }
  }

  const renderListCompanies = () => {
    if (context.companies.length > 0 && context.selectedMarket.years && context.selectedMarket.years.length > 0) {
      return (
        <>
          <h2>{context.companies.length < 30 ? "Liste des entreprises" : "30 premiers établissements employeurs" }</h2>
          <h3>{context.selectedMarket.label}<br/>
            <span>
              {context.selectedMarket.years.filter(item => item.year === context.selectedYear).length > 0 ?
                context.selectedMarket.years.filter(item => item.year === context.selectedYear)[0].size
                :
                0
              } emplois
            </span>
            <br/>
            <span className="date"> au 31/12/{context.selectedYear}</span>
          </h3>
          <div className="list">
            {context.companies.filter(item => parseFloat(item.effectifs[context.selectedYear]) > 0 ).sort((a, b) => {
                return parseInt(a.effectifs[context.selectedYear]) - parseInt(b.effectifs[context.selectedYear]);
              }).reverse().map((item, index) => {
                if (index < 30 ) {
                  return (
                    <div key={index} className="list-item">
                      <h4 onClick={() => props.displayCompanyDetails(item)}>{item.label}</h4> 
                      <span>{renderCountSlice(parseFloat(item.effectifs[context.selectedYear]))}</span>
                    </div>
                  );
                }
                return null ; 
              }
            )}
          </div>
        </>
      )
    }
  }

  const renderCountSlice = (count) => {
    if ( count >= 5000 ) {
      return "5000 emplois et plus"
    } else if (count >= 4000 ) {
      return "4000 à 4999 emplois"
    } else if (count >= 3000 ) {
      return "4000 à 4999 emplois"
    } else if (count >= 2000 ) {
      return "2000 à 2999 emplois"
    } else if (count >= 1000 ) {
      return "1000 à 1999 emplois"
    } else if (count >= 900 ) {
      return "900 à 999 emplois"
    } else if (count >= 800 ) {
      return "800 à 899 emplois"
    } else if (count >= 700 ) {
      return "700 à 799 emplois"
    } else if (count >= 600 ) {
      return "600 à 699 emplois"
    } else if (count >= 500 ) {
      return "500 à 599 emplois"
    } else if (count >= 400 ) {
      return "400 à 499 emplois"
    } else if (count >= 300 ) {
      return "300 à 399 emplois"
    } else if (count >= 200 ) {
      return "200 à 299 emplois"
    } else if (count >= 100 ) {
      return "100 à 199 emplois"
    } else if (count >= 50 ) {
      return "50 à 100 emplois"
    } else if (count >= 20 ) {
      return "20 à 50 emplois"
    } else if (count >= 10 ) {
      return "10 à 20 emplois"
    } else if (count >= 6 ) {
      return "6 à 10 emplois"
    } else if (count >= 3 ) {
      return "3 à 5 emplois"
    } else {
      return "1 à 2 emplois"
    }
  }

  const renderSideBar = () => {
    if(context.activeViz === "vizEco") {
      return renderIntro();
    } else {
      return (
        <div className="list-container">
          <img src={dataVisLogo} alt="logo" width="70%"/>
          {context.activeViz === "vizMarket" ? renderListMarkets() : renderListCompanies() }
        </div>
      )
    }
  }

  return (
    <div className="sidebar">
      {renderSideBar()}
    </div>
  );
}