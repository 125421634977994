import React, {useState , useContext} from 'react';
import { explorerContext } from '../../context/explorerContext.js';

export default function NavLinkViz(props) {
  const [ ecoIsHover, setEcoIshover] = useState(false);
  const [ marketIsHover, setMarketIshover] = useState(false);
  const [ relatedEcoIsHover, setRelatedEcoIshover] = useState(false);

  const context = useContext(explorerContext)


  function imageExists(image_url){
    let objImg = new Image();
    objImg.src = image_url;
    if (objImg.complete) {
      return true
    } else {
      return false;
    }
  }

  const renderEcoLink = () => {
    
    if (props.selectedEco && props.selectedEco.label && props.selectedEco.years) {

      const styledButton = {
        backgroundColor: props.selectedEco.color,
        boxShadow: props.hexToRGBa(props.selectedEco.color, 0.2)+ "0px 0px 0px 4px"
      };
    
      const styledButtonHover = {
        backgroundColor: props.selectedEco.color,
        boxShadow: props.hexToRGBa(props.selectedEco.color, 0.4)+ "0px 0px 0px 6px"
      };

      return (
        <div className="link-container">
          <div className="link-circle"
            onMouseEnter={() => setEcoIshover(!ecoIsHover)}
            onMouseLeave={() => setEcoIshover(!ecoIsHover)}
            onClick={() => props.displayVizEco()}
            style={ecoIsHover ?  styledButtonHover : styledButton}>
            {imageExists(process.env.PUBLIC_URL + '/images/icons/'+ props.selectedEco.slug +'.png') ?
              <img src={process.env.PUBLIC_URL + '/images/icons/'+ props.selectedEco.slug +'.png'} alt="icone-link"/>
              :
              null
            }
            <div className="circle-back">
              <img src={process.env.PUBLIC_URL + '/images/icons/back.png'} alt="icone-link"/> 
            </div>
          </div>
          <p>
            <span className="title-label" style={{color: props.selectedEco.color}}>{props.selectedEco.label}</span><br/>
            {props.selectedEco.years.filter(item => item.year === props.selectedYear).length > 0 ?
              <>
                <span><span className="title-label">{props.selectedEco.years.filter(item => item.year === props.selectedYear)[0].companies}</span> établissements</span><br/>
                <span><span className="title-label">{props.selectedEco.years.filter(item => item.year === props.selectedYear)[0].size}</span> emplois</span>
              </>
              :
                null
            }
          </p>
        </div>
      )
    }
  };

  const renderMarketLink = () => {
    if (props.selectedEco && props.displayMarketLink && context.selectedMarket.label && context.selectedMarket.years.length > 0 ) {

      const styledButton = {
        backgroundColor: props.selectedEco.color,
        boxShadow: props.hexToRGBa(props.selectedEco.color, 0.2)+ "0px 0px 0px 4px"
      };
    
      const styledButtonHover = {
        backgroundColor: props.selectedEco.color,
        boxShadow: props.hexToRGBa(props.selectedEco.color, 0.4)+ "0px 0px 0px 6px"
      };
      
      return (
        <div className="link-container" >
          <div className="link-circle-market"
            onMouseEnter={() => setMarketIshover(!marketIsHover)}
            onMouseLeave={() => setMarketIshover(!marketIsHover)}
            onClick={() => props.context.setActiveViz('vizMarket')}
            style={marketIsHover ?  styledButtonHover : styledButton}>
            <div className="circle-back">
              <img src={process.env.PUBLIC_URL + '/images/icons/back.png'} alt="icone-link"/> 
            </div>
          </div>
          <p>
            <span className="title-label" style={{color: props.selectedEco.color}}>{props.context.selectedMarket.label}</span><br/>
            {props.context.selectedMarket.years.filter(item => item.year === props.selectedYear).length > 0 ?
              <>
                <span><span className="title-label">{props.context.selectedMarket.years.filter(item => item.year === props.selectedYear)[0].companies}</span> établissements</span><br/>
                <span><span className="title-label">{props.context.selectedMarket.years.filter(item => item.year === props.selectedYear)[0].size}</span> emplois</span>
              </>
            :
              null
            }
          </p>
        </div>
      )
    }
  };

  const renderRelatedMarketLink = () => {
    if (props.displayMarketLink && context.selectedMarket.ecosystemes && context.selectedMarket.years.length > 0 ) {
      
      var  relatedEcoSlug = context.selectedMarket.ecosystemes.find((ecoSlug) => ecoSlug !== props.selectedEco.slug);
      var relatedEco = context.allEcoSystemes.find(eco => eco.slug === relatedEcoSlug);
    
      if (relatedEco) {

        const styledRelated = {
          backgroundColor: relatedEco.color,
          boxShadow: props.hexToRGBa(relatedEco.color, 0.2)+ "0px 0px 0px 4px"
        };

        const styledRelatedHover = {
          backgroundColor: relatedEco.color,
          boxShadow: props.hexToRGBa(relatedEco.color, 0.4)+ "0px 0px 0px 6px"
        };

        return (
          <div className="link-container">
            <div className="link-circle"
              onMouseEnter={() => setRelatedEcoIshover(!relatedEcoIsHover)}
              onMouseLeave={() => setRelatedEcoIshover(!relatedEcoIsHover)}
              onClick={() => props.displayVizMarket(relatedEco)}
              style={relatedEcoIsHover ?  styledRelatedHover : styledRelated }>
              {imageExists(process.env.PUBLIC_URL + '/images/icons/'+ relatedEco.slug +'.png') ?
                <img src={process.env.PUBLIC_URL + '/images/icons/'+ relatedEco.slug +'.png'} alt="icone-link"/>
                :
                null
              }
            </div>
            <p>
              <span className="title-label" style={{color: relatedEco.color}}>{relatedEco.label}</span><br/>
              {relatedEco.years.filter(item => item.year === props.selectedYear).length > 0 ?
                <>
                  <span><span className="title-label">{relatedEco.years.filter(item => item.year === props.selectedYear)[0].companies}</span> établissements</span><br/>
                  <span><span className="title-label">{relatedEco.years.filter(item => item.year === props.selectedYear)[0].size}</span> emplois</span>
                </>
                :
                  null
              }
            </p>
          </div>
        );
      };
    };
  };

  return (
    <div className="nav-links-container" style={{ top: props.marginTop}}>
      {renderEcoLink()}
      {renderMarketLink()}
      {renderRelatedMarketLink()}
    </div>
  );
}