import axios from 'axios';
import { API_URL  } from '../utils/utils';

export function getData(url, resolve,reject , requireAuth = false) {
  
  var headerAdmin = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
  }}

  axios.get(API_URL+url , requireAuth ? headerAdmin : null )
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle cancel
      reject(error);
      return "error"
  })
};

export function postData(url, resolve,reject , body , requireAuth = false ) {

  var headerAdmin = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
  }}

  axios.post(API_URL+url , body , requireAuth ? headerAdmin: null )
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      console.log(error.response)
      reject(error);
      return "error"
  })
};

export function postPassword(url, resolve,reject , body ) {
  axios.post(API_URL+url , body )
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error.response)
      reject(error);
      return "error"
  })
};

export function putData(url, resolve,reject , body , requireAuth = false ) {
  var headerAdmin = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
  }}

  axios.put(API_URL+url , body , requireAuth ? headerAdmin: null )
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      console.log(error.response)
      reject(error);
      return "error"
  })
};

export function deleteData(url, resolve,reject) {
  var headerAdmin = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
  }}

  axios.delete(API_URL+url , headerAdmin)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (err) {
      console.log(err)
      reject(err);
      return "error"
  })
}

export function postFile(url, resolve,reject , body , requireAuth = true  ) {
  var headerAdmin = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
  }}

  axios.post(API_URL + url , body , requireAuth ? headerAdmin: null )
  .then(function (response) {
    resolve(response);
  })
  .catch(function (err) {
    console.log(err)
    reject(err);
    return "error"
  })
}
