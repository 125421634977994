import React, { useState , useEffect , useContext  } from 'react';
import { adminContext } from '../../context/adminContext';

import table from '../../assets/images/table.svg';
import loader from '../../assets/images/loader.svg';
import triangle from '../../assets/images/triangle.svg'


export default function  DataSource(props) {
  const admin = useContext(adminContext);

  const [ file , setFile ] = useState()

  const { fileData } = props ;

  function sumbitFile() {
    var formData = new FormData();
    formData.append("file", file , file.name);
    formData.append("id", fileData.id);
    admin.postFile(formData);
    admin.setFileIsOnUpdate(true)
    admin.setFileError()
  };

  useEffect(() => {
    if (file !== undefined) {
      sumbitFile()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  function renderFileState() {
    if (admin.fileError) {
      return (
        <>
        <img src={triangle} style={{ width : 18, marginLeft: 15  }} alt="user" />
        <div className="info">
          <span>{admin.fileError} {file && file.name} </span>
        </div>
      </>
      )
    } else if (admin.fileIsOnUpdate && !admin.fileError) {
      return (
        <>
          <img src={loader} style={{ width : 25 , marginLeft:8 }} alt="loader" />
          <div className="info">
            {/* <span>Le ficher {file.name} est en cours de traitement</span> */}
            <span>Vous recevrez un mail lorsque le processus sera terminé.</span>
            
          </div>
        </>
      )
    } else {
      return (
        <>
          <img src={table} style={{ width : 18, marginLeft: 15  }} alt="user" />
          <div className="info separator">
            <span>{fileData.title} </span>
          </div>
          <div className="info separator">
            <span>{fileData.nbEcosystemes} écosystèmes</span>
          </div>
          <div className="info separator">
            <span>{fileData.nbMarches} marchés</span>
          </div>
          <div className="info">
            <span>{fileData.nbEntreprises} entreprises</span>
          </div>
        </>
      )
    }
  };

  return (
    <div className="row">
      <div className="info-container">
        {renderFileState()}
      </div>
      <div className="icons-container">
        <input
          className="d-none"
          id={fileData.id}
          type="file"
          name={fileData.id}
          onChange={(e) => setFile(e.target.files[0])}/>
        {!admin.fileIsOnUpdate ?
          <label
            className="btn-choose-file"
            htmlFor={fileData.id} >Modifier le fichier</label>
          :
          null
        }
      </div>
    </div>
  )
}
