import React, { useEffect, useState , useContext } from 'react';
import * as d3 from 'd3';
import * as _ from 'lodash';

import { explorerContext } from '../../context/explorerContext';
import { hexToRGBa } from '../../utils/utils';
import NavLinkViz from '../DataViz/NavLinkViz';
import NoData from '../Partials/NoData';
import Loader from '../Partials/Loader';

export default function VizCompanies(props) {
  const context = useContext(explorerContext);
  const [marginTopLink , setMarginTopLink ] = useState();
  const [ displayLinks , setDisplayLinks ] = useState(false);

  let simulation;

  useEffect(() => {
    context.setDisplayLoader(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMarginTopLink(document.querySelector("#graph-companies").clientHeight - 154);
    if (context.companies.length > 0) {
      drawVizCompanies();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.selectedYear , context.companies]);

  const drawVizCompanies = () => {

    let div = d3.select("#graph-companies");
    d3.selectAll("#graph-companies > *").remove();

    if(simulation){
      simulation.stop();
    }

    let decalageX = 0;
    let decalageY = 120;
    let minNodeSizeViz = 30;
    var maxNodeSizeViz = 120;
    if ( window.innerWidth < 1300 ) {
      maxNodeSizeViz = 100;
    }
    let width = document.querySelector("#graph-companies").clientWidth - decalageX;
    let height = document.querySelector("#graph-companies").clientHeight - decalageY;
    let mainCircleSize = width /2.3;

    if (mainCircleSize > 600) {
      mainCircleSize = 600
    }

    setMarginTopLink(mainCircleSize + ((height - mainCircleSize) /2 ) + 45 );

    let companies = _.cloneDeep(context.companies);

    companies.map(item => {
      if (parseFloat(item.effectifs[context.selectedYear]) > 0) {
        return item.size = parseFloat(item.effectifs[context.selectedYear]);
      } else {
        return item.size = 0;
      }
    });

    companies.sort((a, b) => { return a.size - b.size }).reverse();

    let particlesData = [];
    let companiesData = [];

    //SET NODES //

    let maxNodeSize = companies[0].size;
    let minNodeSize = companies[companies.length-1].size;

    if(companies.length > 11) {
      minNodeSize = companies[11].size;
    }

    var maxSizeToDisplay = parseFloat(minNodeSize);

    var setNodeScale = d3.scaleLinear()
    .domain([minNodeSize, maxNodeSize])
    .range([minNodeSizeViz,  maxNodeSizeViz]);

    if(maxNodeSize > 0 && companies.length >= 11) {
      _.each(companies, function (node , i) {
        if ( node.size <= maxSizeToDisplay && node.size > 0  ) {
          if (particlesData.length < 300) {
            node.sizeViz = 5
            particlesData.push(node);
          }
        } if (node.size > maxSizeToDisplay && node.size > 0 ) {
          node.sizeViz = setNodeScale(node.size);
          companiesData.push(node);
        }
      });
    } else {
      _.each(companies, function (node , i) {
        if (node.size > 0 ) {
          node.sizeViz = setNodeScale(node.size);
          companiesData.push(node);
        }
      })
    }

    if (companiesData.length === 0 ) {
      context.setDisplayLoader(false);
      context.setDisplayNoData(true);
      setDisplayLinks(true)
      return null;
    }

    simulation = d3.forceSimulation()
      .force('charge', d3.forceManyBody())
      .force("forceX", d3.forceX(width/2).strength(.5))
      .force("forceY", d3.forceY(height/2).strength(.5))
      .force("collide", d3.forceCollide().radius((d) => {
        return d.sizeViz * 0.7
      }));

    div
      .attr("width", width + "px")
      .attr("height", height + "px");

    let group = div.append("div")
      .attr("class", "group")

    div.selectAll(".background-viz")
      .data([0])
      .enter()
      .append("div")
      .attr("class", "background-viz")
      .style("position", "absolute")
      .style("background-color", context.selectedEco.color)
      .style("margin-top", "10px")
      .style("width", 0 + "px" )
      .style("height", 0 + "px" )
      .style("border-radius", mainCircleSize + "px" )
      .style("opacity", 0);

    group.selectAll(".market-label")
      .data(companiesData)
      .enter()
      .append("p")
      .attr("class", "market-label")
      .attr("id", function(d) { return d.slug })
      .style('position', 'absolute')
      .style("height", function(d) { return d.sizeVis })
      .style("z-index", 20)
      .style("transform", function(d) {
        d.x = width/2
        d.y = height/2
        return "translate("+ (width/2)+ "px," + (height/2) + "px)"
      })
      .style("color", hexToRGBa("#58585a"))
      .text(function (d) {
        return d.label;
      });

    group.selectAll("particles")
      .data(particlesData)
      .enter()
      .append("div")
      .attr("class", "particles")
      .style('position', 'absolute')
      .style("height",  "2px")
      .style("width",  "2px")
      .style("transform", function(d) {
        d.x = width/2
        d.y = height/2
        return "translate("+ (width/2)+ "px," + (height/2) + "px)"
      })
      .style("border-radius", "2px")
      .style("background-color", function(d) {
        return context.selectedEco.color;
      });


    group.selectAll(".circle")
      .data(companiesData)
      .enter()
      .append("div")
      .attr("class", "circle")
      .attr("id", function(d) { return d.slug + d.index })
      .style("width", function(d){  return d.sizeViz + "px"})
      .style("height", function(d){  return d.sizeViz + "px" })
      .style("background-color", context.selectedEco.color )
      .style("transform", function(d) {
        d.x = width/2
        d.y = height/2
        return "translate("+ (width/2)+ "px," + (height/2) + "px)"
      })
      .style("border-radius", function (d) {
        return d.sizeViz + "px";
      })
      .on("click", function (d) {
        simulation.stop()
        props.displayCompanyDetails(d);
      })
      .call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended))
      .on("mouseover", function (d) {

        simulation.stop()

        // d3.select("#"+d.slug )
        //   .style("z-index", 20)

        // d3.selectAll('.particles')
        //   .transition()
        //   .duration(300)
        //   .style("opacity", 0.2 );


        // d3.select(this)
        //   .style("z-index", 10)

        d3.selectAll('.circle')
          .transition()
          .duration(300)
          .style("width", function(item){
            if (item.sizeViz < 50 && item.slug + item.index === d.slug + d.index) {
              return 50 + "px"
            } else {
              return item.sizeViz + "px"
            }
          })
          .style("height", function(item){
            if (item.sizeViz < 50 && item.slug + item.index === d.slug + d.index) {
              return 50 + "px"
            } else {
              return item.sizeViz + "px"
            }
          })
          .style("border-radius", function(item){
            if (item.sizeViz < 50 && item.slug + item.index === d.slug + d.index) {
              return 50 + "px"
            } else {
              return item.sizeViz + "px"
            }
          })
          .style("transform", function(item){
            if (item.sizeViz < 50 && item.slug + item.index === d.slug + d.index) {
              return "translate("+ (d.x - 25) + "px," + (d.y - 25) + "px)";
            } else {
              return "translate("+ (item.x - (item.sizeViz /2)) + "px," + (item.y - (item.sizeViz /2)) + "px)";
            }
          })
          .style("background-color", function (data) {
            if (data.slug !== d.slug) {
              let color = context.selectedEco.color;
              return hexToRGBa(color, 0.1);
            } else {
              let color = context.selectedEco.color;
              return hexToRGBa(color, 1);
            }
          });

        // d3.selectAll('.market-label')
        //   .transition()
        //   .duration(300)
        //   .style("z-index", function (data) {
        //     if (data.slug !== d.slug) {
        //       return 10;
        //     } else {
        //       return 20;
        //     }
        //   })
        //   .style("margin-top", function(data) {
        //     if (data.slug === d.slug) {
        //       return -40 + "px"
        //     }
        //   })
        //   .style("opacity", function (data) {
        //     if (data.slug !== d.slug) {
        //       return 0.2;
        //     } else {
        //       return 1;
        //     }
        //   });

        d3.select(this)
          .select('.circle-hover')
          .style('display', 'block');
      })
      .on("mouseout", function (d) {
        simulation.restart()
        d3.select("#"+d.slug )
          .style("color", hexToRGBa("#58585a"))
          .style("z-index", 4)

        // d3.select("#"+d.slug )
        //   .style("color", hexToRGBa("#58585a"))
        //   .style("z-index", 4)

        // d3.selectAll('.particles')
        //   .transition()
        //   .duration(300)
        //   .style("opacity", 1 );

        // d3.select(this)
        //   .style("z-index", 3)

        d3.selectAll('.circle')
          .style("width", function(d){  return d.sizeViz + "px"})
          .style("height", function(d){  return d.sizeViz + "px" })
          .style("border-radius", function(d){  return d.sizeViz + "px" })
          .transition()
          .duration(300)
          .style("transform", function(item) {
              return "translate("+ (item.x - item.sizeViz /2)+ "px," + (item.y - item.sizeViz /2) + "px)";
          })
          .style("background-color", function (d) {
            let color = context.selectedEco.color;
            return hexToRGBa(color);
          });

        // d3.select(this)
        //   .select('.circle-hover')
        //   .style('display', 'none');

        // d3.selectAll('.market-label')
        //   .transition()
        //   .duration(300)
        //   .style("z-index", 10 )
        //   .style("opacity", 1 )
        //   .style("margin-top", "0px" )
      })
      .append("div")
      .attr("class", "circle-hover")
      .style("height", function (d) {
        if (d.sizeViz < 50 ) {
          return 50 + "px"
        } else {
          return d.sizeViz + "px"
        }
      })
      .style("width", function (d) {
        if (d.sizeViz < 50 ) {
          return 50 + "px"
        } else {
          return d.sizeViz + "px"
        }
      })
      .style("border-radius", function (d) {
        if (d.sizeViz < 50 ) {
          return 50 + "px"
        } else {
          return d.sizeViz + "px"
        }
      })
      // .append("p")
      // .attr("class", "circle-hover-text")
      // .style("z-index", 200)
      // .style("margin-top", function (d) {
      // let value = d.sizeViz / 3;
      //   return value + "px";
      // })
      // .html(function (d) {
      // let value = d.size
      // let fontSize = 22
      // if (d.sizeViz < 50) fontSize = 16
      //   return (
      //       "<span style='text-align: center; font-size:" + fontSize + "px; line-height:" + (d.sizeViz / 4) + "px; font-family: roboto-black;'>" + value + "</span><br/>emplois");
      // });

      let ticked = function() {

        div.selectAll(".circle")
          .style("transform", function(d) { return "translate("+ (d.x - d.sizeViz /2)+ "px," + (d.y - d.sizeViz /2) + "px)" })

        group.selectAll(".particles")
          .style("transform", function(d) {
            return "translate("+ d.x + "px," + d.y + "px)"  })

        group.selectAll(".market-label")
          .style("transform", function (d) {
            return  "translate(" + (d.x - this.offsetWidth / 2) + "px," + (d.y  - this.offsetHeight / 2) + "px)"
        });
      };

      context.setDisplayLoader(false);
      setDisplayLinks(true)

      div.selectAll(".background-viz")
        .transition()
        .duration(500)
        .style("width", mainCircleSize + "px" )
        .style("height", mainCircleSize + "px" )
        .style("opacity", 0.2)

      simulation
        .nodes(_.concat(companiesData, particlesData))
        .on("tick", ticked);

      function dragstarted(d) {
        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      }

      function dragended(d) {
        if (!d3.event.active) simulation.alphaTarget(0).restart();
        d.fx = null;
        d.fy = null;
        simulation.stop()
      }
  }

  d3.select(window).on('resize', drawOnResize);

  function drawOnResize() {
    if(context.companies && document.querySelector("#graph-companies")) {
      drawVizCompanies()
    }
  }

  return (
    <>
      <NoData />
      <Loader />
      <div id="graph-companies" className="dataviz-container">
      </div>
      {displayLinks &&
        <NavLinkViz
          marginTop={marginTopLink}
          selectedEco={context.selectedEco}
          selectedYear={context.selectedYear}
          context={context}
          displayMarketLink={true}
          hexToRGBa={hexToRGBa}
          displayVizMarket={props.displayVizMarket}
          displayVizEco={props.displayVizEco}
        />
      }
    </>
  );
}
