import React, { createContext , useContext, useEffect , useState } from 'react';
import { authContext } from './authContext';
import { getAllUsersAction , getSourceAction , addUserAction , deleteUserAction , getRolesAction , updateUserAction , postFileAction } from '../actions/adminAction';
 
export const adminContext =  createContext();

export default function AdminContextProvider(props) {
  const auth = useContext(authContext);

  const [ usersList , setUserList ] = useState([]);
  const [ fileData , setFileData ] = useState()
  const [ rolesOptions , setRolesOptions ] = useState();
  const [ fileIsOnUpdate, setFileIsOnUpdate ] = useState(false);
  const [ fileError , setFileError ] = useState();
  const [ fileMessage , setFileMessage ] = useState();

  useEffect(() => {
    if (auth.isAdmin) {
      getAllUsers()
      getRoles()
      getSource()
    }
  }, [auth.isAdmin , auth.activePage]);

	const dataState = {
    usersList: usersList,
    rolesOptions : rolesOptions,
    fileData: fileData,
    setFileError: setFileError,
    fileError: fileError,
    fileMessage: fileMessage
  };

  // INTERFACE STATE
  const viewState = {
    fileIsOnUpdate: fileIsOnUpdate,
    setFileIsOnUpdate: setFileIsOnUpdate
  };

  function getAllUsers() {
    getAllUsersAction().then(
      res  => {
        setUserList(res.data)
      }
    ).catch(
      err => {
        console.log('error' ,err.response)
      }
    )
  }

  function updateUser(obj) {
    updateUserAction(obj)
      .then(
        res  => {
          getAllUsers()
        }
      ).catch(
        err => {
          console.log('error' ,err.response)
        }
      )
  }

  function getSource() {
    getSourceAction()
      .then(
        res  => {
          setFileData(res.data[0])
        }
      ).catch(
        err => {
          console.log('error' ,err.response)
        }
      )
  }

  function deleteUser(obj) {
    deleteUserAction(obj)
      .then(
        res  => {
          getAllUsers()
        }
      ).catch(
        err => {
          console.log('error' ,err.response)
        }
      )
  }

  function getRoles() {
    getRolesAction().then(
      res  => {
        setRolesOptions(res.data)
      }
    ).catch(
      err => {
        console.log('error' ,err.response)
      }
    )
  }

  function addUser(obj) {
    addUserAction(obj)
      .then(
        res  => {
          getAllUsers()
        }
      ).catch(
        err => {
          console.log('error' ,err.response)
        }
      )
  }

  function postFile(obj) {
    postFileAction(obj)
      .then(
        res  => {
          setFileIsOnUpdate(true)
          console.log(res.data)
          setFileMessage(res.data.message)
        }
      ).catch(
        err => {
          setFileIsOnUpdate(false)
          setFileError("Erreur dans le traitement du fichier")
          console.log('error' ,err)
        }
      )
  }

  const actions = {
    deleteUser: (id) => deleteUser(id),
    updateUser: (obj) => updateUser(obj),
    addUser: (obj) => addUser(obj),
    postFile: (obj) => postFile(obj),
    getSource: () => getSource(),
  };

  const state = {...dataState , ...viewState , ...actions }

  return <adminContext.Provider value={state}>{props.children}</adminContext.Provider>
}

export const adminContextConsumer = adminContext.Consumer;