import { getData , postData , postFile, putData ,deleteData , } from './index';

export function getAllUsersAction() {
  return new Promise(function(resolve, reject) {
    getData("user",resolve,reject , true);
  })
};

export function getRolesAction() {
  return new Promise(function(resolve, reject) {
    getData("user/roles",resolve,reject , true);
  })
};

export function getSourceAction() {
  return new Promise(function(resolve, reject) {
    getData("source",resolve,reject , true);
  })
};

export function addUserAction(obj) {
  return new Promise(function(resolve, reject) {
    postData("auth/register",resolve,reject , obj , true);
  })
};

export function updateUserAction(obj) {
  return new Promise(function(resolve, reject) {
    putData(`user/${obj.id}`,resolve,reject , obj , true);
  })
};

export function deleteUserAction(id) {
  return new Promise(function(resolve, reject) {
    deleteData(`user/${id}` ,resolve,reject);
  })
};

export function postFileAction(obj) {
  return new Promise(function(resolve, reject) {
    postFile("file/upload",resolve,reject , obj , true);
  })
};
