import React , { useState , useEffect } from 'react';
import arrow from '../../assets/images/arrow.svg';
import Select, { components } from 'react-select';

import userAdmin from '../../assets/images/user-admin.svg';
import userReader from '../../assets/images/user.svg';
// import save from '../../assets/images/save.svg';
import check from '../../assets/images/check.svg';
import checkActive from '../../assets/images/check-active.svg';

import deleteIcon from '../../assets/images/delete.svg';
import deleteIconRed from '../../assets/images/delete-red.svg';

export default function User(props) {
  const { user , options , removeUser , updateUser , authUser } = props;
  const [ userLevel , setUserLevel] = useState(user.role);
  const [ deleteIsOver , setDeleteIsOver ] = useState(false);
  // const [ saveIsActive , setSaveIsActive ] = useState(false);

  const customFilters = {
    container: (provided, state) => ({
      ...provided,
      width: 180,
      fontFamily: 'Roboto',
      margin: 10,
      color: "#0092c5",
      height: 32,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Roboto',
      cursor: "pointer",
      color:'#58585a',
      backgroundColor: state.isFocused ? 'rgb(238, 237, 237)': 'transparent',
      transition:"all 1s cubic-bezier(0.075, 0.82, 0.165, 1)",
      paddingLeft: 12,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),
    control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        backgroundColor: "transparent",
        marginTop: -2,
        border: '0 !important',
   // This line disable the blue border
        boxShadow: '0 !important',
        '&:hover': {
            border: '0 !important'
        }
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex:600,
        fontSize: 13,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color:'#0092c5',
        fontSize: 13,
        fontFamily: 'Roboto',
    }),
    input: (provided, state) => ({
      ...provided,
      color:'#0092c5',
      fontSize: 13,
      fontFamily: 'Roboto',
  }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 13,
  })
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrow} alt="arrow" className={props.selectProps.menuIsOpen? "arrow arrow-up": "arrow arrow-down" } />
      </components.DropdownIndicator>
    );
  };

  function handleChangeUserLevel(val) {
    if (authUser.id !== user.id) {
      setUserLevel(val)
    }
    // setSaveIsActive(true)
  }


  // function SubmitUserUpdate() {
  //   var obj = {
  //     id: user.id,
  //     role: userLevel,
  //     active: userIsActive,
  //   }
  //   updateUser(obj)
  //   setSaveIsActive(false)
  // }

  useEffect(() => {
    var obj = {
      id: user.id,
      role: userLevel,
    }
    if (userLevel !== user.role ) {
      updateUser(obj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLevel])


  return (
    <div className="row">
      <div className="info-container">
        <div className="user-icon">
          {user.role.key < 2 ? 
            <img src={userAdmin}  alt="user-admin" />
            :
            <img src={userReader} alt="user" />
          }
        </div>
        <img src={user.active ? checkActive : check } style={{ width: 20 }} alt="check" />
        <div className="info separator">
          <span>{user.firstname} {user.lastname}</span>
        </div>
        <div className="info separator">
          <span>{user.email}</span>
        </div>
        <div className={`info ${authUser && authUser.id && authUser.id === user.id ? "disabled" :"" }`}>
          {options ?
            <Select
              getOptionLabel={option =>`${option.title}`}
              value={options.filter(option => option.label === userLevel.label)}
              styles={customFilters}
              onChange={(option) => handleChangeUserLevel(option)}
              components={{ DropdownIndicator }}
              options={options} />
            :
            null
          }
        </div>
      </div>
      <div className="icons-container">
        {/* {saveIsActive ?
          <>
            <img
              src={save}
              onClick={() => SubmitUserUpdate()}
              className="save-icon"
              alt="save" />
          </>
          :
          null
        } */}
        {authUser && authUser.id && authUser.id !== user.id ? 
          <img
            src={deleteIsOver ? deleteIconRed : deleteIcon }
            onClick={() => removeUser(user.id)}
            onMouseOver={() => setDeleteIsOver(true)}
            onMouseOut={() => setDeleteIsOver(false)}
            className="delete-icon"
            alt="user" />
          :
          null
        }
      </div>
    </div>
  )
}
